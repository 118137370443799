import { SignInPayload } from "../api/type";

export const storageKey = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
};

export const getSession = () => {
  const stringOfSession = localStorage.getItem(storageKey.accessToken);

  if (!stringOfSession) return null;

  const session = JSON.parse(stringOfSession) as SignInPayload;

  if (
    typeof session !== "object" ||
    !Object.prototype.hasOwnProperty.call(session, "accessToken")
  ) {
    return null;
  }

  return session;
};

export const setSession = (session: SignInPayload) => {
  if (!session.accessToken) return;
  localStorage.setItem(storageKey.accessToken, JSON.stringify(session));
};

export const clearSession = () => {
  localStorage.removeItem(storageKey.accessToken);
};
