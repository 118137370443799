import seller from "./sellerAxios";
import socket from "./socketAxios";
import {
  BroadcaseProduct,
  Broadcasts,
  LiveStatus,
  Option,
  OptionBody,
  OptionsOfProduct,
  Pagination,
  ProductData,
  ProductEditableKey,
  ProductExcelData,
  ProductOption,
  ProductOptionBody,
  ProductPayload,
  Response,
  ShippingBody,
  SignInBody,
  SignInPayload,
  User,
} from "./type";

// 로그인
export const signIn = (body: SignInBody) => {
  return seller.post<
    Response<{
      payload: SignInPayload;
    }>
  >("/auth/login", body);
};

// UUID로 로그인
export const signInUseUUID = (uuid: string) => {
  return seller.post<
    Response<{
      payload: SignInPayload;
    }>
  >("/auth/uuid", { uuid });
};

/**
 * GET
 */
export const GET = {
  user: () => {
    return seller.get<Response<User>>(`/users/me`);
  },

  // 라이브 목록
  broadcasts: (
    params: Pagination & { searchWord?: string; liveStatus?: LiveStatus[] }
  ) => {
    let { page = 1, size = 100, searchWord, liveStatus } = params;

    if (searchWord) searchWord = encodeURIComponent(searchWord);

    return seller.get<Response<Broadcasts>>(`/broadcasts`, {
      params: {
        page,
        size,
        liveStatus: liveStatus?.join(","),
        searchWord: searchWord,
      },
    });
  },

  // 라이브 상세정보
  broadcast: (broadcastId: string) => {
    return seller.get<Response<BroadcaseProduct>>(`/broadcasts/${broadcastId}`);
  },

  // 상품리스트 조회
  products: (broadcastId: string) => {
    return seller.get<Response<ProductPayload>>(
      `/broadcasts/${broadcastId}/products`
    );
  },

  // 상품 옵션 조회
  getOptions: (type: "COLOR" | "SIZE") => {
    return seller.get<Response<Option[]>>(`/products/options`, {
      params: {
        type,
      },
    });
  },

  // 상품별 옵션 조회
  getOptionsOfProduct: (broadcastId: string, productId: string) => {
    return seller.get<Response<OptionsOfProduct[]>>(
      `/broadcasts/${broadcastId}/products/${productId}/options`
    );
  },
};

/**
 * POST
 */
export const POST = {
  // [딘품] 상품추가
  addProduct: (broadcastId: string, data: ProductData) => {
    return socket.post<Response>(`/broadcasts/${broadcastId}/products`, data);
  },

  // [다품] 상품추가 (use excel)
  addManyProduct: (broadcastId: string, data: ProductExcelData) => {
    return socket.post<Response>(
      `/v2/broadcasts/${broadcastId}/products`,
      data
    );
  },

  // 사용자 옵션추가
  addOption: (body: OptionBody) => {
    return seller.post<Response<Option>>("products/options", body);
  },

  // 상품 옵션 추가
  addProductOptions: (
    broadcastId: string,
    productId: number,
    data: ProductOptionBody
  ) => {
    return socket.post<Response>(
      `broadcasts/${broadcastId}/products/${productId}/options`,
      data
    );
  },
};

/**
 * PATCH
 */
export const PATCH = {
  // 상품 정보 수정
  updateProduct: (
    broadcastId: string,
    productId: number,
    data: { [key: string]: number | boolean | string }
  ) => {
    return socket.patch<Response>(
      `/broadcasts/${broadcastId}/products/${productId}`,
      data
    );
  },

  // 사용자 옵션 업데이트
  updateProductOption: (
    broadcastId: string,
    productId: number,
    optionId: number,
    data: ProductOptionBody
  ) => {
    return socket.patch<Response>(
      `/broadcasts/${broadcastId}/products/${productId}/options/${optionId}`,
      data
    );
  },
};

/**
 * PUT
 */
export const PUT = {
  resetProduct: (broadcastId: string) => {
    return seller.put<Response>(`/broadcasts/${broadcastId}/products`, {
      products: [],
    });
  },

  // 라이브 별 배송료 업데이트
  updateShippingInfo: (broadcastId: string, data: ShippingBody) => {
    return seller.put<Response>(
      `/broadcasts/${broadcastId}/shipping-info`,
      data
    );
  },
};

/**
 * DELETE
 */
export const DELETE = {
  // 등록되어 있는 옵션 제거
  removeOption: (optionId: string | number) => {
    return seller.delete<Response>(`/products/options/${optionId}`);
  },

  // 상품 옵션 제거
  removeProductOption: (
    broadcastId: string,
    productId: number,
    optionId: number
  ) => {
    return socket.delete<Response>(
      `/broadcasts/${broadcastId}/products/${productId}/options/${optionId}`
    );
  },

  // 상품 옵션 초기화
  clearProductOption: (broadcastId: string, productId: number) => {
    return socket.delete<Response>(
      `broadcasts/${broadcastId}/products/${productId}/options/all`
    );
  },
};

// 주문관리 /live-product 부분
// 라이브 배송료 수정 API
// PUT /broadcasts/{broadcastId}/shipping-info
// 요청값 : shippingType(필수), shippingCharge(선택),  shippingPurchaseAmount(선택)
// 라이브 조회 API
// GET /broadcasts/{broadcastId}
// 응답값 : shippingType, shippingCharge,  shippingPurchaseAmount  - 라이브에 별도로 설정 안한경우, seller 테이블에 설정된 default 값 리턴
